import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withPrefix } from 'gatsby';

import Header from '../../components/HeaderLH';
import PdpFooter from '../../components/Pdp/PdpFooterLH';
import { LayoutPDP } from '../../components/LayoutPDP';
import SingleCtaButton from '../../components/SingleCtaButton';
import PdpFees from '../../components/Pdp/PdpFees';
import IconPDP from '../../components/Pdp/IconPdp';
import IconSetPDP from '../../components/Pdp/IconSetPdp';
import VerticalContentPDP from '../../components/VerticalContentPDP';
import FlipCardSetLH from '../../components/FlipCardSetLH/FlipCardSetLH';
import CardTilesLH from '../../components/CardTilesLH';
import CardTileSecureLH from '../../components/CardTileSecureLH';
import CardTileLearnMoreLH from '../../components/CardTileLearnMoreLH';
import StickyBar from '../../components/StickyBar';
import GuaranteedMastercardLegalContent from '../../components/ModalContent/GuaranteedMastercardLegalContent';
import SEO from '../../components/SEO/SEO';
import { productApiErrorHandler } from '../../lib/api';
import { useWindowWidthSize } from '../../hooks/useWindowWidthSize';

import { LayoutWrapper } from '@cof/lighthouse-component-library';
import { TextBlock } from '@cof/lighthouse-component-library';
import { Tile } from '@cof/lighthouse-component-library';
import { ReviewCard } from '@cof/lighthouse-component-library';
import { Accordion } from '@cof/lighthouse-component-library';
import { AccordionItem } from '@cof/lighthouse-component-library';

import { useCardDetails } from '../../hooks/useCardDetails';
import Loading from '@cof/graffiti-alley-spray-cans/atoms/Loading';

import pdpIconStepOne from '../../assets/images/pdp-icon-buy.svg';
import pdpIconStepTwo from '../../assets/images/pdp-icon-payments.svg';
import secureGiftIcon from '../../assets/images/pdp-secure-notification.svg';
import pdpIconSupport from '../../assets/images/pdp-icon-support.svg';
import pdpIconChat from '../../assets/images/pdp-icon-chat.svg';
import pdpIconAgent from '../../assets/images/pdp-icon-agent.svg';

import verticalContentCelebrateImage from '../../assets/images/pdp-vertical-content-celebrate-desktop.png';
import verticalContentCelebrateImageTablet from '../../assets/images/pdp-vertical-content-celebrate-tab.png';
import verticalContentCelebrateImageMobile from '../../assets/images/pdp-vertical-content-celebrate-mob.png';
import verticalContentPhoneImage from '../../assets/images/vertical-content-ghr-desktop-en.png';
import verticalContentPhoneImageTablet from '../../assets/images/vertical-content-ghr-tablet-en.png';
import verticalContentPhoneImageMobile from '../../assets/images/vertical-content-ghr-mobile-en.png';
import verticalContentPhoneImageFr from '../../assets/images/vertical-content-ghr-desktop-fr.png';
import verticalContentPhoneImageTabletFr from '../../assets/images/vertical-content-ghr-tablet-fr.png';
import verticalContentPhoneImageMobileFr from '../../assets/images/vertical-content-ghr-mobile-fr.png';
import smartRewardsCardArt from '../../assets/images/pdp-hero-banner-desktop.png';

import './smart-rewards-mastercard.scss';

const brand = 'nfsrm';
const largeScreenBreakpoint = 1024;
const singleCtaLink = '/quickcheck/';

function getContentKey(intl, subPath) {
  return intl.formatMessage({ id: `pages.pdp.${subPath}` });
}

function getImagePerLanguage(intl, enImage, frImage) {
  return intl.locale === 'fr' ? frImage : enImage;
}

const subHeader = (
  <>
    <FormattedMessage id={`pages.pdp.hero.sub-header0`} values={{ sup2: <sup>2</sup> }} />{' '}
    <FormattedMessage
      id={`pages.pdp.hero.sub-header1`}
      values={{
        noRiskCredit: (
          <strong>
            <FormattedMessage id="pages.pdp.hero.no-risk-credit" />
          </strong>
        ),
      }}
    />
  </>
);

const SmartRewardsPDPHeroBanner = () => {
  const windowWidth = useWindowWidthSize();

  return (
    <div className="smart-rewards-card-container">
      <div className="smart-rewards-content">
        <TextBlock
          headerTag="h1"
          headerText={<FormattedMessage id="pages.pdp.hero.h1" values={{ MD: <sup>MD</sup> }} />}
          descriptionText={<FormattedMessage id="pages.pdp.hero.title" values={{ sup1: <sup>1</sup> }} />}
          descriptionFontSizeAs="h1"
        />
        <p className="sub-header" data-testid="card-description">
          <FormattedMessage id="pages.pdp.hero.description" values={{ sup1: <sup>1</sup> }} />
          {windowWidth >= largeScreenBreakpoint && subHeader}
        </p>
        <div className="smart-rewards-image-box">
          <img src={smartRewardsCardArt} alt="" className="aspire-travel-desktop" />
        </div>
        {windowWidth < largeScreenBreakpoint && <p className="sub-header">{subHeader}</p>}
        <SingleCtaButton brand={brand} url={singleCtaLink} shouldDisplaySentencedCase useRedesignLink />
      </div>
      <div className="smart-rewards-image-box-desktop">
        <img src={smartRewardsCardArt} alt="" className="smart-rewards-desktop" />
      </div>
    </div>
  );
};

const ReviewsBanner = ({ intl }) => {
  return (
    <section className="pdp-reviews-banner">
      <h2>{intl.formatMessage({ id: 'pages.pdp.reviews.title' })}</h2>
      <div className="review-card-container">
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.pdp.reviews.review1.author' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.pdp.reviews.review1.text' })}
          />
        </div>
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.pdp.reviews.review2.author' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.pdp.reviews.review2.text' })}
          />
        </div>
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.pdp.reviews.review3.author' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.pdp.reviews.review3.text' })}
          />
        </div>
      </div>
    </section>
  );
};

ReviewsBanner.propTypes = {
  intl: intlShape,
};

const LegalDisclaimer = ({ intl }) => {
  const downloadImage = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 16.3388V13.9388H6.40002V17.9388H17.6V13.9388H16V16.3388H8.00002Z"
        fill="#255F82"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23431 11.3045L9.36568 10.1732L12 12.8075L14.6343 10.1732L15.7657 11.3045L12 15.0702L8.23431 11.3045Z"
        fill="#255F82"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2 13.9389V6.73889H12.8V13.9389H11.2Z" fill="#255F82" />
    </svg>
  );
  const links = [
    {
      text: 'pages.pdp.legal-disclaimer.documents.document1.text',
      url: 'pages.pdp.legal-disclaimer.documents.document1.url',
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document5.text',
      url: 'pages.pdp.legal-disclaimer.documents.document5.url',
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document2.text',
      url: 'pages.pdp.legal-disclaimer.documents.document2.url',
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document3.text',
      url: 'pages.pdp.legal-disclaimer.documents.document3.url',
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document4.text',
      url: 'pages.pdp.legal-disclaimer.documents.document4.url',
    },
  ];

  return (
    <section id="legal-disclaimer-content">
      <h2>
        <FormattedMessage id="pages.aspire-travel.legal-disclaimer.documents" />
      </h2>
      <div className="links">
        {links &&
          links.map(({ text, url }) => (
            <a key={text} href={withPrefix(intl.formatMessage({ id: url }))} target="_blank" rel="noopener noreferrer">
              <div>{downloadImage}</div>
              <FormattedMessage
                id={text}
                values={{
                  boldText: (
                    <b>
                      <FormattedMessage id={text.replace(/text$/, 'values.boldText')} />
                    </b>
                  ),
                }}
              />
            </a>
          ))}
      </div>
      <AccordionItem
        title={<h3>{getContentKey(intl, `legal-disclaimer.legal`)}</h3>}
        id="legal-info-accordion"
        onFocus={() => {}}
        onBlur={() => {}}
        isIndependent
      >
        <GuaranteedMastercardLegalContent brand="nfsrm" intl={intl} />
      </AccordionItem>
    </section>
  );
};

LegalDisclaimer.propTypes = {
  intl: intlShape,
};

const Faq = ({ intl }) => {
  const itemProps = [
    {
      title: <h3>{getContentKey(intl, 'content.faq.q1')}</h3>,
      children: (
        <>
          <p>
            <FormattedMessage
              id={`pages.pdp.content.faq.p1-1`}
              values={{ sup2: <sup>2</sup>, MC: intl.locale === 'fr' ? <sup>MC</sup> : undefined }}
            />
          </p>
          <p>
            <FormattedMessage id={`pages.pdp.content.faq.p1-2`} values={{ sup2: <sup>2</sup> }} />
          </p>
        </>
      ),
    },
    {
      title: <h3>{getContentKey(intl, 'content.faq.q2')}</h3>,
      children: (
        <>
          <p>
            <FormattedMessage
              id={`pages.pdp.content.faq.p2-1`}
              values={{ sup2: <sup>2</sup>, MC: intl.locale === 'fr' ? <sup>MC</sup> : undefined }}
            />
          </p>
          <p>
            <FormattedMessage id={`pages.pdp.content.faq.p2-2`} values={{ sup2: <sup>2</sup> }} />
          </p>
        </>
      ),
    },
    {
      title: <h3>{getContentKey(intl, 'content.faq.q3')}</h3>,
      children: <p>{getContentKey(intl, 'content.faq.p3')}</p>,
    },
    {
      title: <h3>{getContentKey(intl, 'content.faq.q4')}</h3>,
      children: <p>{getContentKey(intl, 'content.faq.p4')}</p>,
    },
    {
      title: <h3>{getContentKey(intl, 'content.faq.q5')}</h3>,
      children: <p>{getContentKey(intl, 'content.faq.p5')}</p>,
    },
  ];

  return (
    <>
      <h2 id="faq" className="faq-title">
        {getContentKey(intl, 'content.faq.title')}
      </h2>
      <Accordion itemProps={itemProps} />
    </>
  );
};

Faq.propTypes = {
  intl: intlShape,
};

const SmartRewardsPDP = injectIntl(({ intl }) => {
  const { isLoading, isError, snippetDetails } = useCardDetails({
    intl,
    brand,
    snippetNames: ['annual-fee', 'purchase', 'cash-advance', 'balance-transfer'],
    options: { type: 'detail' },
    errorHandler: productApiErrorHandler,
    isErrorBoundaried: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  const smartRewardsHeader = () => {
    return <Header intl={intl} />;
  };

  const smartRewardsFooter = () => {
    return <PdpFooter intl={intl} promoSuper={null} />;
  };

  return (
    <LayoutPDP
      containerClass="smart-rewards-container"
      CustomHeader={smartRewardsHeader}
      CustomFooter={smartRewardsFooter}
    >
      <LayoutWrapper className="smart-rewards-banner-wrapper-nfsrm ">
        <SmartRewardsPDPHeroBanner />
      </LayoutWrapper>

      <LayoutWrapper className="icon-set-layout-wrapper-nfsrm">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'content.how-it-works.title')}</h2>
          <IconSetPDP>
            <IconPDP
              caption={getContentKey(intl, 'content.how-it-works.step-1')}
              subCaption={getContentKey(intl, 'content.how-it-works.sub-caption-1')}
              icon={pdpIconStepOne}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.how-it-works.step-2')}
              subCaption={getContentKey(intl, 'content.how-it-works.sub-caption-2')}
              subCaptionSuper="3"
              icon={pdpIconStepTwo}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.how-it-works.step-3')}
              subCaption={getContentKey(intl, 'content.how-it-works.sub-caption-3')}
              icon={secureGiftIcon}
              iconAlt=""
              bolded="bottom"
            />
          </IconSetPDP>
        </div>
      </LayoutWrapper>

      <LayoutWrapper className="card-tiles-layout-wrapper-nfsrm">
        <CardTilesLH contentKeyPrefix="smart-rewards" />
      </LayoutWrapper>

      {isError === false && (
        <LayoutWrapper className="fees-layout-wrapper-nfsrm pdp-gray-background">
          <PdpFees intl={intl} feeSnippets={snippetDetails} brand={brand} />
        </LayoutWrapper>
      )}

      <LayoutWrapper className="vertical-contents-card-layout-wrapper-nfsrm pdp-gray-background">
        <VerticalContentPDP
          titleHeadingTag="h2"
          titleText={getContentKey(intl, 'content.youve-got-this.title')}
          content={getContentKey(intl, 'content.youve-got-this.description')}
          image={
            window.innerWidth >= 1024
              ? verticalContentCelebrateImage
              : window.innerWidth >= 640
              ? verticalContentCelebrateImageTablet
              : verticalContentCelebrateImageMobile
          }
          imageAlt=""
        />
      </LayoutWrapper>

      <LayoutWrapper className="flip-card-set-layout-wrapper-nfsrm pdp-gray-background">
        <FlipCardSetLH />
      </LayoutWrapper>

      <LayoutWrapper className="reviews-layout-wrapper-nfsrm pdp-gray-background">
        <ReviewsBanner wrapperC intl={intl} />
      </LayoutWrapper>

      <LayoutWrapper className="credit-vertical-contents-card-layout-wrapper-nfsrm pdp-gray-background">
        <VerticalContentPDP
          titleHeadingTag="h2"
          titleText={getContentKey(intl, 'content.more-than-just.title')}
          content={getContentKey(intl, 'content.more-than-just.description')}
          image={
            window.innerWidth >= 1024
              ? getImagePerLanguage(intl, verticalContentPhoneImage, verticalContentPhoneImageFr)
              : window.innerWidth >= 640
              ? getImagePerLanguage(intl, verticalContentPhoneImageTablet, verticalContentPhoneImageTabletFr)
              : getImagePerLanguage(intl, verticalContentPhoneImageMobile, verticalContentPhoneImageMobileFr)
          }
          imageAlt=""
        />
      </LayoutWrapper>

      <LayoutWrapper className="card-tile-secure-layout-wrapper-nfsrm pdp-gray-background">
        <CardTileSecureLH
          titleLeft={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.c1' })}
          descriptionLeft={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.p1' })}
          titleRight={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.c2' })}
          descriptionRight={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.p2' })}
          imgRight={secureGiftIcon}
        />
      </LayoutWrapper>

      <LayoutWrapper className="learn-more-wrapper-nfsrm pdp-gray-background">
        <CardTileLearnMoreLH brand="nfsrm" />
      </LayoutWrapper>

      <LayoutWrapper className="icon-set-layout-wrapper-nfsrm pdp-gray-background">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'content.get-personalized.h2')}</h2>
          <IconSetPDP dividers={false}>
            <IconPDP
              caption={getContentKey(intl, 'content.get-personalized.caption-1')}
              subCaption={getContentKey(intl, 'content.get-personalized.sub-caption-1')}
              icon={pdpIconSupport}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.get-personalized.caption-2')}
              subCaption={getContentKey(intl, 'content.get-personalized.sub-caption-2')}
              icon={pdpIconChat}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.get-personalized.caption-3')}
              subCaption={getContentKey(intl, 'content.get-personalized.sub-caption-3')}
              icon={pdpIconAgent}
              iconAlt=""
              bolded="top"
            />
          </IconSetPDP>
        </div>
      </LayoutWrapper>

      <LayoutWrapper className="faq-wrapper-nfsrm">
        <Faq intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className={`legal-disclaimer-layout-wrapper-nfsrm extra-space-bottom`}>
        <LegalDisclaimer intl={intl} />
      </LayoutWrapper>
      <StickyBar contentPosition="center" isVerticalDisplayinSmallScreen={false} stickybarPosition="bottom">
        <Tile>
          <img src={smartRewardsCardArt} alt="" />
        </Tile>
        <div className="stickybar-button single-cta">
          <SingleCtaButton
            brand={brand}
            url={singleCtaLink}
            shouldDisplaySentencedCase
            useRedesignLink
            ctaClassName="stickybar"
          />
        </div>
      </StickyBar>
    </LayoutPDP>
  );
});

export default SmartRewardsPDP;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.pdp.seo.page-title" descriptionKey="pages.pdp.seo.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
